import Cookies from 'universal-cookie';

const cookies = new Cookies();
const cookieExpiration = 31622400;

export function mergeData(data) {
    const orig = getData() || {}
    const updated = Object.assign({}, orig, data)
    setData(updated)

    return updated
}

export function getData() {
    return cookies.get('locator_data')
}

function setData(data) {
    cookies.set('locator_data', data, { path: '/', maxAge: cookieExpiration })
}

// originally location_id and designated_by were stored separately, but with new ability to merge in arbitrary data points
// it is easier to manage data as one object
export function fixBc() {
    const data = getData() || {}
    let changed = false
    const location = cookies.get('designate_id')
    if (!data.location_id && location) {
        changed = true
        data.location_id = location
    }
    const designatedBy = cookies.get('designated_by')
    if (!data.designated_by && designatedBy) {
        changed = true
        data.designated_by = designatedBy
    }
    if (changed) {
        setData(data)
    }
}
