import queryString from 'query-string'
import { fixBc, getData, mergeData } from './localStorage'

// entry point, as function to return early if nothing to do
function initLocator () {
    if (window.locatorLoaded) {
        return // only initialize locator once
    }
    window.locatorLoaded = true

    const apiUrl = 'https://locator.dualsidedapp.com'
    const query = queryString.parse(location.search, {arrayFormat: 'bracket'});
    let roots = document.body.querySelectorAll('.designator');
    let locationDisplays = document.body.querySelectorAll('[data-designator-location]')

    // BC re-arrangement of data
    fixBc()

    // updates cart with custom attributes
    const updateCart = (data) => {
        const baseUrl = window.Shopify?.routes?.root || '/'
        return fetch(`${baseUrl}cart/update.js`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({attributes: data}),
        })
        .then(response => {
            if (!response.ok) {
                console.log('[designator] could not update cart', response)
                return
            }
            return response.json()
        })
    }

    // this exposes an API for locator functionality
    const api = {
        getData: getData,
        setData: data => {
            data = mergeData(data)
            updateCart(data).catch(err => {
                console.log(err)
            })
        },
    }

    const data = getData()
    if (data) {
        updateCart(data)
    }

    const e = new Event('locator.api-ready')
    e.api = api
    window.dispatchEvent(e)

    if (Object.keys(query).length + roots.length + locationDisplays.length === 0) {
        console.log("[designator] nothing to do")
        return // nothing to do
    }

    if (window.Shopify === undefined) {
        console.log("designator not running inside Shopify")
        return;
    }

    const shop = window.Shopify.shop
    // todo consider resolving via script parameter

    const fetcher = (id) => {
        return fetch(`${apiUrl}/locations/${id}`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('could not fetch location')
                }
                return response.json()
            })
    }

    // we're coordinating multiple optional steps, so starting with an empty promise
    let p = Promise.resolve()

    // location displays come first, since they are setting up event listeners
    if (locationDisplays.length) {
        p = p.then(() => {
            return import(/* webpackChunkName: "renderer" */ './locationsRenderer').then(mod => {
                new mod.default(
                    fetcher,
                    locationDisplays,
                )
            })
        })
    }

    p = p.then(() => {
        // resolve current designation, either from historic or new designation
        return new Promise((resolve, reject) => {
            let designatedLocation = getData()?.location_id
            let locationToDesignate = query['designate-location'];
            if (locationToDesignate && (locationToDesignate != designatedLocation)) { // change designation
                // verify id is valid/assigned to shop
                fetcher(locationToDesignate).then(data => {
                    api.setData({
                        designated_by: 'landing-page',
                        location_id: locationToDesignate,
                    })
                    resolve(data)
                }).catch(reject)
                return null
            }
            if (designatedLocation) {
                fetcher(designatedLocation).then(resolve).catch(reject)
                return null
            }
            resolve(null)
        }).then(data => {
            if (!data) {
                return
            }

            console.log('[designator] locator.designated dispatched')
            const e = new Event('locator.designated')
            e.location = data
            window.dispatchEvent(e)
        }).catch(err => {
            console.log(err) // todo better handling?
        })
    })

    const onDesignateBase = (location, params) => {
        const e = new Event('locator.designated')
        e.location = location
        window.dispatchEvent(e)

        api.setData(params)
    }

    const onDesignate = (id, params = {}) => {
        if (id === null) {
            onDesignateBase(null, Object.assign({}, params, {
                location_id: null,
            }))
            return
        }

        fetcher(id).then(data => {
            onDesignateBase(data, Object.assign({}, params, {
                location_id: id,
            }))
        })
    }

    if (roots.length) {
        p = p.then(() => {
            return import(/* webpackChunkName: "designator" */ './designator').then(mod => {
                mod.render(shop, roots, onDesignate, data?.location_id)
            })
        })
    }
}

document.addEventListener("DOMContentLoaded", initLocator)
